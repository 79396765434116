import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import member1 from '../assets/team/jeph.jpeg';
import member2 from '../assets/team/chris.png';
import member3 from '../assets/team/random.jpg';

const TeamSectionContainer = styled.div`
  background: #000;
  color: #FFFFFF;
  padding: 50px 10px;
  text-align: center;
`;

const TeamTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
`;

const TeamTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
`;

const TeamMembersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
`;

const TeamMemberCard = styled.div`
  background: #212121;
  border-radius: 10px;
  overflow: hidden;
  text-align: left;
  width: 300px;

  img {
    width: 100%;
    height: 300px; /* Fixed height for all images */
    object-fit: cover; /* Ensure image covers the container */
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const TeamMemberContent = styled.div`
  padding: 20px;
`;

const MemberName = styled.h3`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const MemberPosition = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ViewProfileLink = styled(Link)`
  color: #4cd4e4;
  font-size: 18px;
  text-decoration: underline; /* Add underline to the link */
  transition: color 0.3s;

  &:hover {
    color: #32bfd4;
  }
`;

const OurTeamButton = styled(Link)`
  display: inline-block;
  margin-top: 40px;
  padding: 20px 20px;
  background: #4cd4e4;
  color: #0B0B0D;
  font-size: 18px;
  border-radius: 5px;
  text-decoration: none;
  transition: background 0.3s;

  &:hover {
    background: #32bfd4;
  }
`;

const TeamSection = () => {
  const teamMembers = [
    {
      name: 'Jephthah Abanum',
      position: 'CEO',
      image: member1,
      link: '/team/jeff',
    },
    {
      name: 'Chris Fega',
      position: 'CTO',
      image: member2,
      link: '/team/jane',
    },
    {
      name: 'Michael Johnson',
      position: 'Legal Advisor',
      image: member3,
      link: '/team/michael',
    },
  ];

  return (
    <TeamSectionContainer>
      <TeamTitleContainer>
        <TeamTitle>Our Team</TeamTitle>
      </TeamTitleContainer>
      <TeamMembersContainer>
        {teamMembers.map((member, index) => (
          <TeamMemberCard key={index}>
            <img src={member.image} alt={member.name} />
            <TeamMemberContent>
              <MemberName>{member.name}</MemberName>
              <MemberPosition>{member.position}</MemberPosition>
              <ViewProfileLink to={member.link}>View Profile</ViewProfileLink>
            </TeamMemberContent>
          </TeamMemberCard>
        ))}
      </TeamMembersContainer>
      <OurTeamButton to="/team">View All Team Members</OurTeamButton>
    </TeamSectionContainer>
  );
};

export default TeamSection;