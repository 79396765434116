// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/logo/1.png';

const FooterContainer = styled.footer`
  background: #000010;
  color: #FFFFFF;
  padding: 40px 80px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Column = styled.div`
  flex: 1;
  margin: 0 10px;
  min-width: 200px;

  @media (max-width: 768px) {
    margin: 20px 0;
    min-width: 250px;
  }
`;

const LogoContainer = styled.div`
  margin-bottom: 20px;

  img {
    width: 150px;
  }
`;

const ColumnTitle = styled.h4`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ColumnText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
`;

const FooterLink = styled(Link)`
  color: #FFFFFF;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 5px;
  display: block;

  &:hover {
    color: #4cd4e4;
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
  }
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 300px;
  border: 1px solid #444;
  border-radius: 5px;
  background: #1B1B1B;
  color: #FFFFFF;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: #4cd4e4;
  color: #0B0B0D;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #32bfd4;
  }
`;

const FooterBottomBar = styled.div`
  background: #000010; /* New background color */
  padding: 20px 0; /* Increased height */
  font-size: 14px;
  text-align: center;
  width: 100%; /* Full width */
`;

const Footer = () => {
  return (
    <>
      <FooterContainer>
        <FooterTop>
          <Column>
            <LogoContainer>
              <img src={logo} alt="Jayco Links Logo" />
            </LogoContainer>
            <ColumnText>
              Jayco Links is dedicated to creating high-value products that drive success for businesses and delight users worldwide.
            </ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Address</ColumnTitle>
            <ColumnText>22 Dr. Omon Ebhomenye Street, Off Admiralty Way,</ColumnText>
            <ColumnText>Lekki Phase 1, Lekki Lagos 106104</ColumnText>
            <ColumnText>Nigeria</ColumnText>
          </Column>
          <Column>
            <ColumnTitle>Quick Links</ColumnTitle>
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/services">Services</FooterLink>
            <FooterLink to="/products">Products</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
          </Column>
          <Column>
            <ColumnTitle>Subsidiaries</ColumnTitle>
            <FooterLink to="/subsidiary1">Rendezvous Luxury Care</FooterLink>
            <FooterLink to="/subsidiary2">Ndonu</FooterLink>
            <FooterLink to="/subsidiary3">Swiiph</FooterLink>
            <FooterLink to="/subsidiary4">Leap Bubble</FooterLink>
          </Column>
          <Column>
            <ColumnTitle>Newsletter</ColumnTitle>
            <ColumnText>Subscribe to our newsletter to get the latest updates.</ColumnText>
            <NewsletterForm>
              <Input type="email" placeholder="Enter your email" />
              <Button type="submit">Subscribe</Button>
            </NewsletterForm>
          </Column>
        </FooterTop>
      </FooterContainer>
      <FooterBottomBar>
        &copy; {new Date().getFullYear()} Jayco Links. All Rights Reserved.
      </FooterBottomBar>
    </>
  );
};

export default Footer;