// src/components/CallToActionBar.js
import React from 'react';
import styled from 'styled-components';

const CallToActionBarContainer = styled.div`
  background: linear-gradient(90deg, #00203F, #1B1B2F);
  color: #FFFFFF;
  padding: 40px 20px;
  text-align: center;
`;

const CallToActionContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const CallToActionTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 20px;
`;

const CallToActionMessage = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
`;

const CallToActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
`;

const CTAButton = styled.a`
  background: #4cd4e4;
  color: #0B0B0D;
  padding: 15px 25px;
  font-size: 18px;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  transition: background 0.3s;

  &:hover {
    background: #32bfd4;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 10px 20px;
  }
`;

const CallToActionBar = () => {
  return (
    <CallToActionBarContainer>
      <CallToActionContent>
        <CallToActionTitle>Join Our Team Today, Invest in Your Future</CallToActionTitle>
        <CallToActionMessage>
          At Jayco Links, we're committed to building a brighter future. Whether you're looking to grow your career or invest in innovative solutions, we offer exciting opportunities for you to make a difference.
        </CallToActionMessage>
        <CallToActionButtons>
          <CTAButton href="/careers">Join Our Team</CTAButton>
        </CallToActionButtons>
      </CallToActionContent>
    </CallToActionBarContainer>
  );
};

export default CallToActionBar;