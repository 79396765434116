import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../assets/logo/logo_icon.png'; 

const HeaderContainer = styled.header`
  background: #000010;  
  padding: 20px 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;

  img {
    width: 50px; 
    margin-right: 3px;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  a {
    color: #FFFFFF;
    margin: 0 15px;
    text-decoration: none;
    font-size: 16px;

    &:hover {
      color: #00AEEF; 
      text-decoration: underline; 
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    background: #0B0B0D;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;

    a {
      margin: 20px 0;
      font-size: 24px;
    }
  }
`;

const MenuIcon = styled.div`
  display: none;
  color: #FFFFFF;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseIconContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
`;

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [open]);

  return (
    <HeaderContainer>
      <LogoContainer to="/">
        <img src={logo} alt="Jayco Links Logo" />
        Jayco Links
      </LogoContainer>
      <MenuIcon onClick={() => setOpen(!open)}>
        <FaBars />
      </MenuIcon>
      <Nav open={open}>
        {open && (
          <CloseIconContainer onClick={() => setOpen(false)}>
            <FaTimes />
          </CloseIconContainer>
        )}
        <Link to="/about" onClick={() => setOpen(false)}>About</Link>
        <Link to="/products" onClick={() => setOpen(false)}>Products</Link>
        <Link to="/services" onClick={() => setOpen(false)}>Services</Link>
        <Link to="/investors" onClick={() => setOpen(false)}>For Investors</Link>
        <Link to="/career" onClick={() => setOpen(false)}>Career</Link>
        <Link to="/contact" onClick={() => setOpen(false)}>Contact</Link>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;