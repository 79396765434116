import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';

const IntroductionContainer = styled.div`
  background: #000010;
  color: #f5f5f5;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  padding-right: 20px;

  @media (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const TextContainer = styled.div`
  flex: 2;
  max-width: 700px;
  
`;

const IntroductionTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
`;

const IntroductionText = styled.p`
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 30px;
  
`;

const ReadMoreLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: #000;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #32bfd4;
  }

  svg {
    margin-left: 10px;
  }
`;

const Introduction = () => {
  return (
    <IntroductionContainer>
      <ImageContainer>
        <Image src={require('../assets/images/cartoon.png')} alt="Introduction Image" />
      </ImageContainer>
      <TextContainer>
        <IntroductionTitle>Inspired to Foster Growth</IntroductionTitle>
        <IntroductionText>
          Jayco Links is a household name when it comes to curation of investments, dedicated to creating high-value products that drive success for businesses and delight users worldwide. We specialize in innovative solutions tailored for B2B clients and are committed to building a brighter digital future.
        </IntroductionText>
        <ReadMoreLink to="/about">
          Read More <FaArrowRight />
        </ReadMoreLink>
      </TextContainer>
    </IntroductionContainer>
  );
};

export default Introduction;