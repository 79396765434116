import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const InvestmentAreaContainer = styled.div`
  background: #00010;
  color: #000;
  padding: 80px 80px;
  text-align: center;
  
  @media (max-width: 768px) {
    padding: 80px 30px;
  }
`;

const InvestmentAreaTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 50px;
`;

const InvestmentAreaDescription = styled.p`
  font-size: 20px;
  max-width: 500px;
  margin: 0 auto 40px;
`;

const InvestmentAreasWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TextColumn = styled.div`
  flex: 1;
  padding: 70px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
    padding: 0;
    max-width: auto;
  }
`;

const InvestmentCardsColumn = styled.div`
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InvestmentAreaRow = styled.div`
  background: ${({ color }) => color || '#212121'};
  padding: 40px;
  border-radius: 10px;
  text-align: left;
  flex-basis: calc(50% - 10px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  font-weight: bold;
  color: #fff;
  
  margin-bottom: 10px;
`;

const CardDescription = styled.p`
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
`;

const ReadMoreLink = styled(Link)`
  color: #4cd4e4;
  font-size: 18px;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #32bfd4;
  }
`;

const DescriptionText = styled.p`
  font-size: 25px;
  line-height: 1.6;
  color: #f5f5f5;
  font-weight: 500;
  
  @media (max-width: 768px) {
    font-size: 20px;
    margin: 0;
    padding-bottom: 20px;
  }
`;

const InvestmentArea = () => {
  const investmentAreas = [
    {
      title: 'Technology',
      description: 'Investing in cutting-edge technology companies that are shaping the future.',
      color: '#4ce4e4',
      link: '/technology',
    },
    {
      title: 'Healthcare',
      description: 'Supporting innovative healthcare solutions to improve global health outcomes.',
      color: '#ff6f61',
      link: '/healthcare',
    },
    {
      title: 'Renewable Energy',
      description: 'Fostering sustainable energy projects that contribute to a cleaner planet.',
      color: '#ffd700',
      link: '/renewable-energy',
    },
    {
      title: 'Finance',
      description: 'Backing financial services that provide value and innovation in the financial sector.',
      color: '#90ee90',
      link: '/finance',
    },
  ];

  return (
    <InvestmentAreaContainer>
      <InvestmentAreaTitle></InvestmentAreaTitle>
      <InvestmentAreaDescription>
        
      </InvestmentAreaDescription>
      <InvestmentAreasWrapper>
        <TextColumn>
          <DescriptionText>
            Jayco Links strategically invests in sectors that we believe will drive the future of industry and innovation. From technology to healthcare, renewable energy to finance, our investment areas are chosen to ensure sustainable growth and long-term value.
          </DescriptionText>
          <ReadMoreLink to="/investment-areas">Read More</ReadMoreLink>
        </TextColumn>
        <InvestmentCardsColumn>
          {investmentAreas.map((area, index) => (
            <InvestmentAreaRow key={index} color={area.color}>
              <CardTitle>{area.title}</CardTitle>
              <CardDescription>{area.description}</CardDescription>
            </InvestmentAreaRow>
          ))}
        </InvestmentCardsColumn>
      </InvestmentAreasWrapper>
    </InvestmentAreaContainer>
  );
};

export default InvestmentArea;