import React, { useState } from 'react';
import styled from 'styled-components';
import teamImage from '../assets/about/team.jpeg';

const AboutContainer = styled.div`
  background: #11111a;
  color: #FFFFFF;
  padding: 100px 20px;
  text-align: center;
`;

const AboutTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 80px;
`;

const AboutSubtitle = styled.h2`
  font-size: 32px;
  margin-bottom: 20px;
  margin-top: 100px;
`;

const AboutText = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
`;

const CardStackContainer = styled.div`
  position: relative;
  height: 400px;
  margin: 0 auto;
  width: 800px;
  margin-bottom: 100px;

  @media (max-width: 768px) {
    width: 90%;
    height: 300px;
  }
`;

const Card = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 20px;
  width: 100%;
  height: 100%;
  background: #212121;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transform: ${({ active }) => (active ? 'translateY(-20px)' : 'translateY(0)')};
  z-index: ${({ active }) => (active ? 2 : 1)};
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-20px);
  }

  @media (max-width: 768px) {
    padding: 15px;
    height: 120%;
    &:hover {
      transform: none;
    }
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const CardText = styled.p`
  font-size: 18px;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Section = styled.section`
  margin: 40px 0;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }
`;

const ImageContainer = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const SectionText = styled.div`
  width: 50%;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

const SectionTitle = styled.h3`
  font-size: 28px;
  margin-bottom: 20px;
`;

const TabContainer = styled.div`
  display: flex;
  margin-top: 40px;
  border: 1px solid #333;
  border-radius: 10px;
  overflow: hidden;
  margin: 50px 100px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin: 40px 10px;
  }
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  background: #212121;
  width: 300px;

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    overflow-x: auto;
  }
`;

const Tab = styled.button`
  padding: 35px;
  background: ${({ active }) => (active ? '#4cd4e4' : '#212121')};
  color: #fff;
  border: none;
  border-bottom: 1px solid #333;
  cursor: pointer;
  font-size: 18px;
  text-align: left;
  

  &:hover {
    background: #4cd4e4;
  }

  &:last-child {
    border-bottom: none;
  }

  @media (max-width: 768px) {
    flex: 1;
    text-align: center;
    border-bottom: none;
    border-right: 1px solid #333;
    padding: 20px;

    &:last-child {
      border-right: none;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  padding: 40px 80px;
  background: #333;
  text-align: left;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const InvestmentContent = styled.p`
  font-size: 18px;
  line-height: 1.6;
`;

const AboutPage = () => {
  const [activeCard, setActiveCard] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const cards = [
    {
      title: 'Our Mission',
      text: 'At Jayco Links, our mission is to harness the power of strategic investments to deliver exceptional value and growth. We are committed to fostering innovation, supporting sustainable practices, and prioritizing customer satisfaction across all our ventures. Our approach is guided by a steadfast commitment to excellence, integrity, and long-term success.',
    },
    {
      title: 'Our History',
      text: 'Founded in [Year], Jayco Links has evolved from a visionary startup into a global investment powerhouse. Over the years, we have built a reputation for our strategic foresight, rigorous investment process, and ability to adapt to changing market dynamics. Our journey is characterized by a relentless pursuit of excellence and a strategic approach that has enabled us to thrive in dynamic and competitive markets.',
    },
  ];

  const tabs = [
    { title: 'Real Estate', content: 'Our investments in real estate encompass residential, commercial, and industrial properties. We focus on acquiring and developing prime real estate assets that promise robust returns and sustainable growth.' },
    { title: 'Agriculture', content: 'Understanding the vital role agriculture plays in global food security and economic stability, Jayco Links invests in innovative agricultural practices and technologies. Our investments aim to enhance productivity, promote sustainability, and support rural development.' },
    { title: 'Technology Products', content: 'In an era defined by rapid technological advancement, Jayco Links is at the forefront of investing in cutting-edge tech products and startups. We seek out disruptive technologies and innovative solutions that have the potential to transform industries and improve lives.' },
    { title: 'Logistics', content: 'Recognizing the critical importance of efficient logistics in a globalized economy, we invest in companies that are revolutionizing supply chain management and logistics operations. Our focus is on enhancing efficiency, reducing costs, and improving delivery times.' },
    { title: 'Finance', content: 'Our investments in the financial sector aim to support fintech innovations and financial services that drive inclusion and economic empowerment. We back initiatives that offer groundbreaking solutions in banking, payments, lending, and wealth management.' },
  ];

  return (
    <AboutContainer>
      <AboutTitle>The Company</AboutTitle>
      <AboutSubtitle>Investing in Tomorrow, Today</AboutSubtitle>
      <AboutText>
        Jayco Links is a dynamic investment company dedicated to generating substantial returns through strategic investments across a broad spectrum of industries. With a vision rooted in innovation and growth, Jayco Links has established itself as a formidable player in the investment landscape, leveraging its deep expertise and market insights to identify and capitalize on high-potential opportunities.
      </AboutText>

      <CardStackContainer>
        {cards.map((card, index) => (
          <Card
            key={index}
            active={index === activeCard}
            onClick={() => setActiveCard(index)}
          >
            <CardTitle>{card.title}</CardTitle>
            <CardText>{card.text}</CardText>
          </Card>
        ))}
      </CardStackContainer>

      <AboutSubtitle>Our Investment Portfolio</AboutSubtitle>
      <TabContainer>
        <Tabs>
          {tabs.map((tab, index) => (
            <Tab key={index} active={index === activeTab} onClick={() => setActiveTab(index)}>
              {tab.title}
            </Tab>
          ))}
        </Tabs>
        <Content>
          <InvestmentContent>{tabs[activeTab].content}</InvestmentContent>
        </Content>
      </TabContainer>

      <Section>
        <ImageContainer>
          <img src={teamImage} alt="Our Team" />
        </ImageContainer>
        <SectionText>
          <SectionTitle>Our Team</SectionTitle>
          Our team is composed of talented professionals who are experts in their respective fields. Together, we work towards our common goal of delivering high-quality investment solutions and driving innovation across industries. The collective expertise of our team, combined with our collaborative culture, empowers us to achieve outstanding results and create lasting value for our stakeholders.
        </SectionText>
      </Section>
    </AboutContainer>
  );
};

export default AboutPage;