import React from 'react';
import styled from 'styled-components';

const MissionContainer = styled.div`
  background: #000010;
  color: #f5f5f5;
  padding: 50px 20px;
  text-align: center;
`;

const MissionTitle = styled.h2`
  font-size: 26px;
  margin-bottom: 20px;

   @media (min-width: 768px) {
    font-size: 36px;
  }
`;

const MissionText = styled.p`
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 30px;
`;

const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Point = styled.div`
  max-width: 300px;
  margin: 10px 20px;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const PointTitle = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
`;

const PointText = styled.p`
  font-size: 20px;
  
  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

const Mission = () => {
  return (
    <MissionContainer>
      <MissionTitle>What We Represent</MissionTitle>
      <MissionText>
        At Jayco Links, our mission is to harness the power of technology to deliver exceptional products that meets value. We are passionate about innovation, quality, and value.
      </MissionText>
      <PointsContainer>
        <Point>
          <PointTitle>Unwavering Integrity</PointTitle>
          <PointText>We act with honesty and integrity at all times. This is critical to our business operations and we expect the same from the stakeholders we work with.</PointText>
        </Point>
        <Point>
          <PointTitle>Total Discipline</PointTitle>
          <PointText>At JayCo Links, discipline is the bedrock of the company culture. We tailor our decisions towards activities that help us fulfil our business goals.</PointText>
        </Point>
        <Point>
          <PointTitle>Unending Committment </PointTitle>
          <PointText>We build structures and systems that ensure sustainable socio-economic value. Our investment process reflects the founder’s commitment to the social economic development of the communities where we operate.</PointText>
        </Point>
      </PointsContainer>
    </MissionContainer>
  );
};

export default Mission;