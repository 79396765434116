// src/pages/HomePage.js
import React from 'react';
import HeroSection from '../components/HeroSection';
import Introduction from '../components/Introduction';
import Mission from '../components/Mission';
import TeamSection from '../components/TeamSection';
import InvestmentArea from '../components/InvestmentArea';
import CallToActionBar from '../components/CallToActionBar';
import Partners from '../components/Partners';

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <Introduction />
      <Partners />
      <Mission />
      <InvestmentArea />
      <TeamSection/>
      <CallToActionBar/>
  
    </div>
  );
};

export default HomePage;