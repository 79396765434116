import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
  background: url(${require('../assets/images/hero4.jpg')}) no-repeat center center/cover; 
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 50px 0;

  @media (max-width: 768px) {
    padding: 80px 20px;
    height: auto;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TextContainer = styled.div`
  max-width: 60%;
  text-align: left;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
  }
`;

const HeroTitle = styled.h1`
  font-size: 68px;
  line-height: 1.2;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

const HeroSubtitle = styled.p`
  font-size: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const LearnMoreButton = styled.button`
  background: #4cd4e4;
  color: #0b0b0d;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 18px;
  transition: background 0.3s;

  &:hover {
    background: #32bfd4;
  }

  @media (max-width: 768px) {
    padding: 15px 25px;
    font-size: 18px;
    font-weight: 400;
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <Container>
        <TextContainer>
          <HeroTitle>The All New Premium Solutions Investment </HeroTitle>
          <HeroSubtitle>Bulding businesses, enhancing lives and connecting you to the world</HeroSubtitle>
          <LearnMoreButton>Learn More</LearnMoreButton>
        </TextContainer>
      </Container>
    </HeroContainer>
  );
};

export default HeroSection;