import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import partner1 from '../assets/partners/ndonu_partners.png'; 
import partner2 from '../assets/partners/probitas_partners.png';
import partner3 from '../assets/partners/tunnels_partners.png';
import partner4 from '../assets/partners/Vendgram-Black@2x.png';
import partner5 from '../assets/partners/adbirt.png';
import partner6 from '../assets/partners/forcythe2.jpeg';
import partner7 from '../assets/partners/vezeti.jpeg';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const PartnersContainer = styled.div`
  background: #000010;
  padding: 50px 20px;
  text-align: center;
`;

const PartnersTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 50px;
  color: #fff;
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    display: flex;
    justify-content: center;
  }

  .slick-list {
    margin: 0 -2px;
  }

  .slick-slide > div {
    padding: 0 5px;
  }

  @media (max-width: 768px) {
    .slick-slide > div {
      padding: 0 3px;
    }

    .slick-dots {
      bottom: -20px;
    }
  }
`;

const LogoWrapper = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  width: 100%;
  max-width: 120px;
  max-height: 60px;
  object-fit: contain;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1); 
  }

  @media (max-width: 768px) {
    max-width: 80px;
    max-height: 40px;
  }
`;

const Partners = () => {
  const settings = {
    dots: true,
    arrows: false,  
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <PartnersContainer>
      <PartnersTitle>Our Partners</PartnersTitle>
      <StyledSlider {...settings}>
        <LogoWrapper>
          <Logo src={partner1} alt="Partner 1" />
        </LogoWrapper>
        <LogoWrapper>
          <Logo src={partner2} alt="Partner 2" />
        </LogoWrapper>
        <LogoWrapper>
          <Logo src={partner3} alt="Partner 3" />
        </LogoWrapper>
        <LogoWrapper>
          <Logo src={partner4} alt="Partner 4" />
        </LogoWrapper>
        <LogoWrapper>
          <Logo src={partner5} alt="Partner 5" />
        </LogoWrapper>
        <LogoWrapper>
          <Logo src={partner6} alt="Partner 6" />
        </LogoWrapper>
        <LogoWrapper>
          <Logo src={partner7} alt="Partner 7" />
        </LogoWrapper>
      </StyledSlider>
    </PartnersContainer>
  );
};

export default Partners;