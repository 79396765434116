import React from 'react';

const ProductsPage = () => {
  return (
    <div>
      <h1>Our Products</h1>
      <p>This is the Products page.</p>
    </div>
  );
};

export default ProductsPage;